import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('vertical-nav-menu',{attrs:{"is-drawer-open":_vm.isDrawerOpen},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen=$event}}}),_c(VAppBar,{attrs:{"app":"","flat":"","absolute":"","color":"transparent"}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"d-flex align-center mx-6"},[_c(VAppBarNavIcon,{staticClass:"d-block d-lg-none me-2",on:{"click":function($event){_vm.isDrawerOpen = !_vm.isDrawerOpen}}}),_c(VSpacer),_c('theme-switcher'),_c('app-bar-user-menu')],1)])]),_c(VMain,[_c('div',{staticClass:"app-content-container boxed-container pa-6"},[_vm._t("default")],2)]),_c(VFooter,{staticClass:"px-0",attrs:{"app":"","inset":"","color":"transparent","absolute":"","height":"56"}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"mx-6 d-flex justify-space-between"},[_c('span',[_vm._v(" © 2022 "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://vms.vermas.my","target":"_blank"}},[_vm._v("Vermas Management System")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
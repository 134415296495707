import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '../main'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    QrData: null,
    QrAction: null,
  },
  getters: {
    user(state) {
      return state.user
    },
    QrData(state){
      return state.QrData
    }, 
    QrAction(state){
      return state.QrAction
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_USER(state, data) {
      state.user.data = data
    },
    SET_QRDATA(state, value) {
      state.QrData = value
    },
    SET_QRACTION(state, value) {
      state.QrAction = value
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        const docRef = db.collection('users').doc(user.uid)
        docRef.get().then(doc => {
          if (doc.exists) {
            commit('SET_USER', {
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              role: doc.data().role,
            })
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        }).catch(error => {
          console.log('Error getting document:', error)
        })
      } else {
        commit('SET_USER', null)
      }
    },
    parseQrData({commit},QrData){
      if (QrData !== null)
      commit('SET_QRDATA',QrData)
    },
    parseQrAction({commit}, QrAction){
      if(QrAction !== null)
      commit('SET_QRACTION',QrAction)
    }
  },
  modules: {},
})

export default store

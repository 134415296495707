import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
// import './registerServiceWorker'
import wb from "./registerServiceWorker"
import VueQrCode from 'vue-qrcode-reader'
import axios from 'axios'
import { firestorePlugin } from 'vuefire'
import MultiFiltersPlugin from './plugins/MultiFilters'

Vue.use(firestorePlugin)

Vue.prototype.$workbox = wb

Vue.config.productionTip = false

Vue.prototype.$http = axios

Vue.use(VueQrCode)

Vue.use(MultiFiltersPlugin)

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA9_HddPT4RyJ9hg-9c_qKvbDj7uLK_ANc",
  authDomain: "vermas-farmer.firebaseapp.com",
  projectId: "vermas-farmer",
  storageBucket: "vermas-farmer.appspot.com",
  messagingSenderId: "780794494524",
  appId: "1:780794494524:web:6da9b86eca0ec9a877cd39"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig)

firebase.auth().onAuthStateChanged(user => {
  store.dispatch('fetchUser', user)
})

export const db = firebaseApp.firestore()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

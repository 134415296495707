import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/clock-in-out',
    name: 'clock-in-out',
    component: () => import('@/views/forms/FormClockInOut.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/record-form',
    name: 'record-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormDaily.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/feeding-form',
    name: 'feeding-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormFeeding.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/new-form',
    name: 'new-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormNew.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/population-form',
    name: 'population-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormPopulation.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/add-form',
    name: 'add-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormAdd.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/water-form',
    name: 'water-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormWater.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/harvest-form',
    name: 'harvest-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormHarvest.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/complete-form',
    name: 'complete-form',
    component: () => import('@/views/forms/worm-bin-monitor/SubmissionComplete.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/summary-form',
    name: 'summary-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinSummary.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/query-form',
    name: 'query-form',
    component: () => import('@/views/forms/worm-bin-monitor/BinFormQuery.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/claim-form',
    name: 'claim-form',
    component: () => import('@/views/forms/FormClaim.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/table-attendance',
    name: 'table-attendance',
    component: () => import('@/views/table/TableAttendance.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/table-attendance-fault',
    name: 'table-attendance-fault',
    component: () => import('@/views/table/TableAttendanceFault.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/table-claims',
    name: 'table-claims',
    component: () => import('@/views/table/TableClaims.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/qr-scan',
    name: 'qr-scan',
    component: () => import('@/views/qrscanner/QrScanner.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: { requiresAuth: true }, 
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      hideForAuth: true,
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      requiresAuth: true,
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(user => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!user) {
        next({ path: '/login' })
      } else {
        next()
      }
    } else {
      next()
    }

    if (to.matched.some(record => record.meta.hideForAuth)) {
      if (user) {
        next({ path: '/dashboard' })
      } else {
        next()
      }
    } else {
      next()
    }
  })
})

export default router
